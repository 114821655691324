@import "../variables";

h1{
    color: white;
}

.section-title{
    margin: 2rem 0 2rem 2rem;
}

.projects{
    display: flex;
    justify-content: center;
    padding-top: 6rem;
}

.project-list{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin: 1rem 0 2rem 0;
}

.project-card{
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-project{
    display: flex;
    flex-direction: column;
    width: $desktop-content-width;
}

.button-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
}

.button-container{
    height: 100px;
}

@media (max-width: 1000px) {
    .projects{
       margin: 0;
    }
    .container-project{
       margin: 0;
    }
    .project-list{
        margin: 0;
        margin-bottom: 1rem;
    }

    .cv-container{
        margin-top: 1rem !important;
    }

}

@media (max-width: 500px) {
    .projects{
       padding-top: 4rem;
    }
    
    .section-title{
        margin: 0 0 0 1rem;
    }
}