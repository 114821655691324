@import "../variables";

h1{
    color: white;
}

.footer{
    display: flex;
    justify-content: center;
    margin-top: 10rem;
    color: white;
}

.container-footer{
    display: flex;
    flex-direction: column;
    width: $desktop-content-width;
    align-items: center;
    overflow: hidden;

    .footer-description{
      margin: 0 1rem;
      text-align: center;
    }

    .section-title{
      margin: 2rem 0 0.5rem 0;
      font-size: 2rem;
    }

    .footer-credit{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .opacity-transition {
        transition: opacity 0.3s ease-out;
      }
      

      .gradient-background{
        position: absolute;
        bottom: -10px;
        height: 100%;
        width: 100%;
        z-index: 0;
      }

      .gradient-background-small{
        position: absolute;
        bottom: 0;
        height: 50%;
        width: 100%;
        opacity: 0.1;
        background: radial-gradient(at bottom, rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 50%, /* Adjust this value as needed */
        rgba(255, 255, 255, 0) 100%);
      }

    }

    .footer-credit-text{
      font-size: 1rem;
      font-weight: 600;
      margin: 2rem;
      z-index: 1;
      text-align: center;

      a{
        color: $accent-color;
      }

      a:hover{
        text-decoration: underline;
      }
    }
}

.card-container{
    display: flex;
    flex-direction: row;
    margin: 2rem 1rem 0 1rem;
    width: 700px;
    flex-wrap: wrap;
    font-size: 22px;
    justify-content: center;

    .footer-card{
      background-color: rgba(255,255,255,0.1);
      border-radius: 4px;
      display: flex;
      width: 300px;
      padding: 0.5rem 1rem;
      margin: 0.5rem;
      font-weight: 800;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      color: white;
    }

    .footer-card:hover{
      background-color: rgba(255,255,255,0.2);

    }

    .card-content{
      display: flex;
      justify-content: center;
      align-items: center;

      .card-icon{
        margin-right: 0.5rem;
      }

      p{
        font-size: 1rem;
      }
    }
}

.seperator-line{
  height: 1px;
  background-color: rgba(255,255,255,0.1);
  width: 200px;
  margin: 3rem 0 0 0;
}

.nowrap {
  white-space: nowrap;
}



@media (max-width: 1000px) {
    .card{
      width: 100%;
    }
}

@media (max-width: 1200px) {
  .container-footer{
     width: 100%;
  }
}

@media (max-width: 700px) {

  .card-container{
    width: fit-content;

    .footer-card{
      width: 100%;
   }
  }
  
}


