@import "../variables";

.project-window-window{
    z-index: 999;
    border-radius: 2rem 2rem 2rem 2rem;
}

.project-window-header{
    background-color: rgba(255,255,255);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem 1rem 2rem;
}

.project-window-title{
    color: black;
    font-size: 2rem;
}

#close{
    transition: all 0.2s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

#close:hover{
    cursor: pointer;
    color: rgb(81, 81, 81);
}

.project-window-content{
    padding: 1rem 2rem 2rem 2rem;
    display: flex;
    justify-content:space-between;
    background-color: white;
    overflow: auto;
}

.project-window-text{
    width: 100%;
    color:white;
    padding-top: 1rem;
    padding-bottom: 3rem;

    span{
        margin-bottom: 20px;
    }
}

.project-window-img{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
img{
    object-fit: cover;
    object-position: top;
    width: 100%;
    height: auto;
    max-height: 600px;
}

}

@media (max-width: 900px) {
    .project-window-content{
        padding: 1rem 2rem 3rem 2rem;
        display: flex;
        flex-direction: column;
    }

    .project-window-header{
        padding: 0rem 2rem 0rem 2rem;
    }

    .project-window-text{
        width: 100%;
    }
    .project-window-img{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img{
            width:80%;
        }
    }
    
  
}

.project-window-technologies{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.technology{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255,255,255,0.1);
    margin: 0.2rem;
    padding: 0 0.5rem;
    height: 34px;
    border-radius: 6px;
    p{
        margin: 0;
        padding: 0;
        color: white;
        font-weight: 600;
        margin-left: 6px;
        font-size: 14px;
    }
    img{
        height: 18px;
    }
}

.container-link-tech{
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-top: 0.5rem;
    margin-bottom: 1rem;

    .links{
        display: flex;
        p{
            color: white;
            font-weight: 600;
            margin: 0.5rem 0;
        }
        
        a{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .link-icon-github{
            margin: 0.2rem 0.5rem 0 0.5rem;
            padding: 0 0.5rem;
            min-height: 34px;
            border-radius: 6px;
            border: 1px white solid;
        }

        .link-icon-github:hover{
            background-color: rgba(255,255,255,0.15);
            transition: all 0.2s ease-in;
        }

        #highlight{
            border: none;
        }

        .master-button { //Overwrite MasterButton component style
            height: 34px !important;
            border-radius: 6px !important;
            margin: 0 !important;
            padding: 0 0.5rem !important;

            p{
                margin: 0 !important;
            }
        }

        .master-button-animation { //Overwrite MasterButton component style
            height: 34px !important;
            border-radius: 6px !important;
            margin: 0 !important;
            padding: 0 0.5rem !important;
        }
    
        .link-icon{
            color: white;
            height: 18px;
            width: 18px;
        }
    }
   
}


.project-window-img {
    position: relative;
    display: inline-block; /* or other display types as per your layout */
  }
  
  .project-window-img img {
    display: block; /* to remove bottom space/line */
    width: 100%; /* adjust as necessary */
  }
  
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .4s ease;
    background-color: rgba(0, 0, 0, 0.6); 
    display: flex;
    justify-content: center;
    align-items: center;

    p{
        color: white;
        font-size: 1.2rem;
        margin-right: 0.3rem;
    }

    .link-icon{
        color: white;
        height: 18px;
        width: 18px;
    }
  }
  
  .project-window-img-link:hover .overlay {
    opacity: 1;
  }
  
  .overlay span {
    color: white;
    font-size: 20px; /* adjust size as needed */
    text-align: center;
  }


  @media (max-width: 500px) {
    .container-link-tech{
        display: flex;
        flex-direction: column;
    
        .links{
            margin-top: 1rem;
            display: flex;
            a{
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .link-icon-github{
                margin: 0.2rem 0.5rem 0.2rem 0.2rem;
                padding: 0 0.5rem;
                min-height: 42px;
                border-radius: 6px;
                border: 1px white solid;
            }

            .master-button { //Overwrite MasterButton component style
                min-height: 42px !important;
                height: fit-content !important;

                p{
                    margin: 0.5rem !important;
                }
            }
    
            .master-button-animation { //Overwrite MasterButton component style
                min-height: 42px !important;
                height: fit-content !important;

                p{
                    margin: 0.5rem !important;
                }
            }
    
        }

        .link-icon{
            margin-left: 0.3rem;
        }

        .technology{
            height: 42px;
        }
    }
    
    .project-window-text{
        padding-top: 0rem;
    }
    
  }