.tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .tooltip-box {
    position: absolute;
    background: rgb(255, 255, 255, 0.1);
    color: white;
    border-radius: 4px;
    padding: 8px;
    font-size: 14px;
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  
  .tooltip-box.visible {
    opacity: 1;
  }
  
  /* Positioning */
  .tooltip-top {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 0.5rem;
  }
  
  .tooltip-bottom {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 0.5rem;
  }
  
  .tooltip-left {
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 0.5rem;
  }
  
  .tooltip-right {
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 0.5rem;
  }
  
  /* Arrows */
  .arrow {
    position: absolute;
    width: 0; 
    height: 0; 
    border-style: solid;
  }
  
  .tooltip-top .arrow {
    background-color: rgba($color: #000000, $alpha: 0);
    bottom: -5px;
    left: 50%;
    border-width: 15px 15px 0 15px;
    border-color: black transparent transparent transparent;
    transform: translateX(-50%);
  }
  
  .tooltip-bottom .arrow {
    background-color: rgba($color: #000000, $alpha: 0);
    top: -5px;
    left: 50%;
    border-width: 0 15px 15px 15px;
    border-color: transparent transparent rgba(255, 255, 255, 0.1) transparent;
    transform: translateX(-50%);
  }
  
  .tooltip-left .arrow {
    background-color: rgba($color: #000000, $alpha: 0);
    right: -5px;
    top: 50%;
    border-width: 15px 0 15px 15px;
    border-color: transparent transparent transparent black;
    transform: translateY(-50%);
  }
  
  .tooltip-right .arrow {
    background-color: rgba($color: #000000, $alpha: 0);
    left: -5px;
    top: 50%;
    border-width: 15px 15px 15px 0;
    border-color: transparent black transparent transparent;
    transform: translateX(-50%);
  }

  .triangle {
    top: -6px;
    left: calc(50% - 6px);
    width: 0; 
    height: 0; 
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    /* Change 'border-bottom' to 'border-top' for an upward-pointing triangle */
    border-bottom: 6px solid rgba(255, 255, 255, 0.1); 
    position: absolute;
  
    /* Additional styles */
  }
  
  