@import "./variables";

.navbar-desktop {
  width: 100%;
  padding: 5px 15px;
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 1000000;

  .container-navbar{
  
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: $desktop-content-width;
    
  }
  
  
  .logo {
    justify-content: flex-start;
    color:white;
    font-weight: bold;
    margin: 1rem 1rem 1rem 1rem;
    text-decoration: none;
  
    img{
      height: 50px;
    }
  
  }
  
  .logo:hover {
    text-decoration: none;
    color: $accent-color;
  }
  
  .navbar-nav {
    display: flex;
    flex-direction: row;
  }
  
  .item{
    display: flex;
    margin: 1.5rem 0rem;  
    color: white;
    list-style-type: none;
  
    .menu-icon{
      margin-right: 0.5rem;
      font-size: 1.3rem;
    }
  }
  
  .link {
    text-decoration: none;
    color: white;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1.5rem;

    svg{
      margin: 0;
    }
  }
  
  .link:hover {
    color: $accent-color;
  }

}



//Mobile

  .navbar-mobile {
    width: 100%;
    padding: 0px 0px;
    position: fixed;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 900;

    .navbar-nav {
      display: flex;
      justify-content:space-around;
      width: 100%;
    }
    
    .container-navbar{
      margin: 0rem;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: $desktop-content-width;
      
    }

    .item{
      display: flex;
      justify-content: space-between;
      margin: 1.3rem 0rem 1.3rem 0rem;
    }

    @media (max-width: 400px ) {
      .item{
        font-size: 16px;
      }

      .navbar-nav {
        display: flex;
        justify-content:space-around;
        width: 100%;

      }

    }
  }

  .bm-cross-button{
    height: 0 !important;
    width: 0 !important;
  }

  .menu-icon-mobile{
    position: fixed;
    top: 10px;
    color: rgb(255, 255, 255);
    display: flex; /* Use flexbox to center content */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    cursor: pointer;
    z-index: 1300;
    margin: 0.5rem;
    transition: right 0.3s ease; /* Add transition */
}

.menu-icon-mobile-hero{
    position: fixed;
    top: 0px;
    left: 0px;
    display: flex; /* Use flexbox to center content */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    cursor: pointer;
    z-index: 1300;

    .logo{
      margin: 16px 20px;

      img{
        height: 40px;
      }
    }
}

  .menu-icon{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    p{
      color: rgba(255,255,255,0.5);
      margin-bottom: 0.5rem;
      font-size: 16px;
    }

    .menu-icon-links{

        a {
          color: white;
          font-size: 1.5rem;
          padding: 1rem;
          background-color: rgba(255,255,255,0.05);
          border-radius: 4px;
          display: flex;
          align-items: center;
          margin-top: 0.5rem;

          p{
            font-size: 1rem;
            color:  white;
            margin: 0 0.5rem 0 0.5rem;
          }
        }

        a:hover{
          color: $accent-color;
          background-color: rgba(255,255,255,0.1);

          p{
            color: $accent-color;
          }
        }
      
     }
    
     .menu-sections{
      display: flex;
      flex-direction: column;
     }
  }

  .mobile-menu-container{
    .item{
      display: flex;
      margin: 2rem 0rem;  
      color: white;
      list-style-type: none;
    
      .menu-icon{
        margin-right: 0.5rem;
        font-size: 1.3rem;
      }
    }
    
    .link {
      text-decoration: none;
      color: white;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
  
      svg{
        margin: 0;
      }
    }
  
    .link:hover {
      color: $accent-color;
    }
  }
 

  // Mobile menu expanded

  .bm-burger-button{
    position: fixed;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 36px;
  }
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    transition: all 0.3s ease !important;

  }
  .bm-menu {
    background: linear-gradient(117.41deg, #242e51 -6.56%, #252e54 18.43%, #191E3A 43.13%, #15192f 70.54%, #141730 87.27%);

    padding: 3.5em 1em 1rem;
    font-size: 1.15em;
    text-align: left;
    display: flex;
    justify-content: start;
    align-items: start;
    transition: all 0.3s ease !important;
  }

  .bm-item{
    width:100%;
  }

  .bm-item.item{
    margin: 0;
    padding: 1rem 0;
  }

  .bm-item-list{
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    align-items: start;
    width: 100%;
  }

  .burger-icon {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 35px;
    height: 37px;
  }

  .burger-line {
    height: 3px;
    border-radius: 2px;
    width: 100%;
    background-color: white;
    transition: all 0.3s ease;
  }

  /* Transformations for open state */
  .burger-icon.open .burger-line:nth-child(1) {
    transform: translateY(12px) rotate(45deg);
  }

  .burger-icon.open .burger-line:nth-child(2) {
    opacity: 0;
    transform: translateX(20px); /* Ensures a smooth transition */
  }

  .burger-icon.open .burger-line:nth-child(3) {
    transform: translateY(-14px) rotate(-45deg);
  }


