@import "../variables";

h1{
    color: white;
}

.education{
    display: flex;
    justify-content: center;
    padding-top: 6rem;
    color: white;
}

.container-educations{
    display: flex;
    flex-direction: column;
    width: $desktop-content-width;

    .section-description{
      margin: 1rem;
    }
}

@media (max-width: 1000px) {
    .education{
       margin: 0;
    }
    .container-education{
       margin: 0;
    }
}

@media (max-width: 1200px) {
  .education{
     margin: 0;
  }
  .container-educations{
     width: 100%;
  }
}


/* TIMELINE */

/* Add these CSS rules to your Education.scss file */

.timeline {
  height: 12px;
  border-radius: 5px;
  background-color: rgb(185, 185, 185);
  margin: 6rem 1rem;
  position: relative;


  .progress-indicator-wrapper{
    display: flex;
    justify-content: end;

    .triangle-up {
      margin-top: 5px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 10px solid $accent-color /* Change the color as needed */
    }

    .process-indicator{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transform: translateX(45%);
    }

    .progress-text{
      margin-top: 5px;
      border-radius: 4px;
      padding: 0.5rem 0.5rem;
      background-color: rgba(255,255,255,0.1);

      p{
        font-weight: 800;
        margin: 0;
        color: $accent-color;
      }

    }
  }
}

  .timeline-background {
    position: relative;
    width: 100%;
    height: 100%; // Make sure it covers the whole timeline area

    .hover-selector{
      flex: 1; // Distribute the space evenly among the 5 divs
      height: 100%;
      border-radius: 14px;
      background-color: rgba(255,255,255,0.05);
      position: absolute;
      transform: translateX(100%);
      transition: 0.3s ;
      width: 20%;
      opacity: 1;
      cursor: pointer;
    }

    .invisible{
      transition: 1s ;
      opacity: 0;
    }
  
    .hover-regions {
      display: flex;
      position: absolute;
      width: 100%;
      height: 120px; 
      top: 50%;
      transform: translateY(-50%); // Center vertically around the timeline
      z-index: 1; // Ensure it's above the background but below the labels
  
      .hover-region {
        cursor: pointer;
        flex: 1; // Distribute the space evenly among the 5 divs
        height: 100%;
      }
    }
  }
  
  .timeline-bar {
    height: 100%;
    border-radius: 5px;
    position: absolute;
    top: 0; // Position the bar at the top of the timeline container
}
  
.timeline-bar.progress {
  background-color: $accent-color; // Only the progress bar will have the accent color
}
  
  .timeline-labels {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    top: 50%; /* Position labels in the middle of the timeline */
    transform: translateY(-50%);
  }
  
  .timeline-label {
    position: absolute;
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
    left: 0; /* Labels will be adjusted using the left property */
    transform: translateX(-50%);
    padding: 0.4rem 0.6rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65px;
  }
  
  .timeline-label.above {
    top: -45px; /* Position labels above the timeline */
  }
  
  .timeline-label.below {
    bottom: -45px; /* Position labels below the timeline */
  }

  .timeline-label.hovered {
    color: $accent-color;
    background-color: rgba(255,255,255,0.2);
  }

  .hover-info{
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255,255,255,0.5);
    margin: 0 1rem 2rem 1rem;
    text-align: center;
  }

  .hover-info.active{
    color: white;
  }

  /* Course info */

  .courses-container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    padding: 0 5rem;
    transition: all 0.3s ease-in-out;

    .slide-button-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      pointer-events: none; /* Allows clicks to pass through when not over buttons */
    }

    .slide-button {
      position: absolute;
      pointer-events: auto; /* Enables clicks on the buttons */
      cursor: pointer;
      border: none;
      height: 90%;
      z-index: 9999;
      transition: all 0.1s;
      padding: 2rem;
      cursor: pointer;
    }

    .slide-button:hover {
      background-color: rgba(255,255,255,0.05);
    }
    
    .slide-button.leftbutton {
      width: fit-content;
      left: 0;
    }
    
    .slide-button.rightbutton {
      right: 0;
    }
    
    .slide-button-icon {
      /* Your icon styles */
    }
}

.courses-container.invisible{
}

.courses-wrapper {
    display: flex;
    width: 100%;
    transition: all 0.3s ease-in-out;
}

.year-courses {
    flex-shrink: 0;
    width: 100%; /* Each year's courses take up the full width */
    display: flex;
    flex-wrap: wrap;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease-in-out;
    height: fit-content;

    .course-title{
      margin: 0;
      font-weight: 800;
      font-size: 18px;
      color: white;
    }

    .course-grade{
      margin: 0;
    }
}

.year-courses.active {
    opacity: 1; /* Make the active year visible */
}

.year-courses.hidden {
    opacity: 0.2;

    .course-title{
      visibility: hidden;
    }
    .course-code{
      visibility: hidden;
    }
    .course-grade{
      visibility: hidden;
    }
}

.course-wrapper {
    background-color: rgba(255,255,255,0.1);
    padding: 1rem;
    margin: 0.5rem;
    border-radius: 1rem;
    flex-grow: 3;
}

  .courses-wrapper.hidden{
    transform: translateY(-100%);
  }
  .courses-wrapper-horizontal {
    display: flex;
    width: 100%;
  }
  
  .courses-wrapper-vertical {
    display: flex;
    width: 100%;
    transition: transform 0.3s ease-in-out; /* Transition only for vertical movement */
  }
  
  .courses-wrapper-vertical.hidden {
    transform: translateY(-100%);
  }
  

  .course-code{
    opacity: 0.5;
    margin-top: 0;
  }

  .hide-button{
    padding: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon{
      margin-right: 0.3rem;
    }
    
  }

  .hide-button:hover{
    color: $accent-color;
  }

  
@media (max-width: 550px) {
  .courses-container{
    padding: 0 3rem;

    .slide-button{
      padding: 1rem;
    }
  }

}
