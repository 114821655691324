$card-heigth: 480px;
$card-width: 280px;

$card-heigth-small: 400px;
@import "../variables";

.card{
    height: $card-heigth;
    width: $card-width;
    display:flex;
    flex-direction: column;
    margin: 2rem 2rem 2rem 2rem;
    transition: all 1s;
    z-index: 1;
    transition-timing-function: ease-out;
    
    div{
        background-position: center !important;
    }
    
    .tech-project-card{
        transition: all 1s;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255,255,255,0.1);
        margin: 0.2rem;
        padding: 0 0.5rem;
        height: 34px;
        border-radius: 6px;
        img{
            height: 18px;
        }
    }

    .img_icon{
        transition: all 0.5s;
        transition-timing-function: ease-out;
        filter: grayscale(100%);
    }

    .img_icon.hovering{
        filter: grayscale(0%);
    }

    .p-container{
        transition: 0.3s;
        transition-timing-function: ease-out;
        width: 100%;
        padding-bottom: -20px;
        overflow: hidden;
    }

    .text-wrapper{
        transition: 0.3s;
        transition-timing-function: ease-out;
        overflow: hidden;
        height: 65px;
    }

    .text-wrapper.expanded{
        height: 100px;
    }

    .p-container.expanded{
    }

}


.card:hover{   
    cursor: pointer;
}

.summary{
    width: 150px;
}

.top{
    height: 2.2/3 * $card-heigth;
    border-radius: 1rem 1rem 0rem 0rem;
    filter: grayscale(100%);
    transition: all 0.3s;
    transition-timing-function: ease-out;
}

.top-compressed{
    background-position: center;
    width: 100%;
    height: 2/3 * $card-heigth;
    border-radius: 1rem 1rem 0rem 0rem;
    filter: grayscale(0%);
    transition: all 0.3s;
    transition-timing-function: ease-out;
}


.bottom{
    height: 0.8/3 * $card-heigth;
    background-color: rgba(255,255,255,0.2);
    border-radius: 0rem 0rem 1rem 1rem;
    transition: all 0.3s;
    transition-timing-function: ease-out;
}

.bottom-expanded{
    height: 1/3 * $card-heigth;
    background-color: rgba(255,255,255,0.2);
    border-radius: 0rem 0rem 1rem 1rem;
    transition: all 0.3s;
    transition-timing-function: ease-out;
}


.title{
    font-weight: 800;
    margin: 1rem 1rem 0rem 1rem;
    color: white;
    letter-spacing: 1px;
}


.tags{
    transition: all 1s !important;
    display: flex;
    bottom: 50px;
    color: #ffd500;
    font-size: 14px;
    margin-left: 0.8rem;
    position: absolute !important;
    width: 248px !important;
}


.marquee-content{
    display: flex;
}

.summary {
    margin: 0rem 1rem 0rem 1rem;
    font-size: 16px;
    width: 85%;
    color: white;
    opacity: 1;
    transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out;
}

.summary-invisible {
    margin: 0rem 1rem 0rem 1rem;
    font-size: 16px;
    width: 85%;
    color: white;
    opacity: 0;
    transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out;
}


@media (max-width: 700px) {
    .card{
        height: $card-heigth-small;
        width: 100%;
        margin: 1rem 1rem 1rem 1rem;
    }

    .top{
        height: 2.05/3 * $card-heigth-small;
    }
    .top-compressed{
        height: 2.05/3 * $card-heigth-small;
    }


    .bottom{
        height: 0.95/3 * $card-heigth-small;
    }
    .bottom-expanded{
        height: 0.95/3 * $card-heigth-small;
    }
 
    .project-card{
        width: 100%;
    }

    .project-card-container{
        width: 100%;
    }
    .tags{
        bottom:25px;
    }

}

@media (max-width: 400px) {
    .tags{
        width: 80%;
    }
}