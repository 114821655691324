@import "../variables";
/* Modal.scss */
.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    background-color: rgba(255, 255, 255, 1);
    z-index: 1300;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(117.41deg, #242e51 -6.56%, #252e54 18.43%, #191E3A 43.13%, #1C213F 70.54%, #141730 87.27%);

  }

  .modal-header{
    height: 100px;
    padding: 0 3rem 0 3rem;
    display: flex;
    justify-content: center;
    width: 1200px;
    

    .modal-header-content{
      width: $desktop-content-width;
      display: flex;
      align-items: end;
      justify-content: space-between
    }
    

    .modal-title{
      text-transform: uppercase;
      h1{
        font-size: 2rem;
        font-weight: 600;
        color: #ffffff;
        margin: 0;
        padding: 0;
      }
      p{
        font-weight: 600;
        color: $accent-color;
        margin: 0;
      }
      
      
    }
  }

  .modal-content{
    width: $desktop-content-width;
    max-height: calc(100vh - 150px); // You can set this to whatever value you find suitable
    padding: 0 3rem 0 3rem;
  }
  
  .close-button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .close-icon{
        font-size: 38px;
        color: white;
    }
    .close-icon:hover{
        color: $accent-color;
    }
  }

  @media (max-width: ($desktop-content-width)) {
    .modal-content{
      width: 100%;
      padding: 0 1rem;

    }

    .modal-header{
      padding: 0 1rem;
      width: 100% !important;
    }

  }
  
  //Mobile
  @media (max-width: 500px) {

    .close-button{
      position: absolute;
        right: 0.5rem;
        top: 0.5rem;
    }

    .modal-header{ 
      height: fit-content;

      .close-button{
        margin-right: 0px;
      }

      .modal-header-content{
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 1.5rem 2.5rem 0.5rem 2.5rem !important;
      }      
      .modal-title{
      font-size: 1.7rem;
      text-align: center;

      p{
        font-size: 1rem;
      }
    }
  }

    .modal-content {
      position: relative; /* Add position relative to the parent */
    }

  
    .close-icon {
      font-size: 40px !important;
      color: white;
    }
  }

  .no-scroll {
    overflow: hidden;
  }

  