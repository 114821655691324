@import "../variables.scss";

.master-button{
  height: 40px;
  padding: 0rem 2rem 0rem 2rem;
  color: white;
  font-weight: 600;
  border-radius: 7rem;
  background: $gradient;
  transition: all 0.5s;
  position: relative;
  overflow: hidden;
  min-width: 200px;

  .button-icon{
    margin-right: 0.3rem;
    height: 1.25rem;
    width: 1.25rem;
}
}

.master-button-animation{
  height: 40px;
  padding: 0rem 2rem 0rem 2rem;
  color: white;
  font-weight: 600;
  border-radius: 7rem;
  background: $gradient;
  transition: all 0.5s;
  position: relative;
  animation: shine 0.5s;
  overflow: hidden;
  justify-content: center;
}

.master-button:hover{
  cursor: pointer;
}

.master-button::before{
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, #fff, transparent);
  transition: 0.5s;
}

.master-button:hover::before{
  left: 100%;
}


.master-button-animation::before{
  content: '';
  min-width: 200px;
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, #fff, transparent);
  transition: 0.5s;
}

.master-button-animation:hover::before{
  left: -100%;
}

.loader{
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height:100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-text{
  display: flex;
  justify-content: center;
  align-items: center;
}

#visible
{ 
  animation: inAnimation 450ms ease-in ;
};

#invisible
{ 
  animation: outAnimation 470ms ease-out;
  animation-fill-mode: forwards;
};



@keyframes inAnimation {
  0% {
    opacity: 0;
    max-height: 0px;
  }
  100% {
    opacity: 1;
    max-height: 600px;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
    max-height: 600px;
  }
  100% {
    opacity: 0;
    max-height: 0px;
  }
}



