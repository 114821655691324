@import "../variables";

h1{
    color: white;
}

.section-title{
    margin: 0rem 0.5rem 0rem 1rem;
}

.aboutme{
    display: flex;
    justify-content: center;
    padding-top: 6rem;
    margin-bottom: 3rem;
}

.container-about{
    display: flex;
    flex-direction: column;
    width: $desktop-content-width;

    .cv-container{
        display: flex;
        width: inherit;
        justify-content: center;
        align-items: center;
        margin-top: 3rem;

    }
}

.about-me-content{
    width: $desktop-content-width;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: initial;
    margin: 1rem 0 0 0;
    flex-wrap: wrap;
}

@media (max-width: $desktop-content-width ) {
    .about-me-content{
        width: 100%;
    }
}

.box1{
    margin-top: 6rem;
    width: 30%;
}

.box2{
    margin-top: 4rem;
    width: 30%;
}

.box3{
    width: 30%;
}

@media (max-width: 1000px) {
    .box1{
        width: 100%;
        margin-top:0;
    }
    
    .box2{
        width: 100%;
        margin-top:0;
    }
    
    .box3{
        width: 100%;
    }

    .about-me-content{
        margin: 0;
    }

}

@media (max-width: 600px){
    .section-title{
        margin: 0 0 1rem 1rem;
    }
}