@import "./variables";
//1rem = 16px


html { height: 100%; overflow:auto; }
body { height: 100%; }

* {
    box-sizing: border-box;  
  }

  .main{
    background: linear-gradient(117.41deg, #242e51 -6.56%, #252e54 18.43%, #191E3A 43.13%, #1C213F 70.54%, #141730 87.27%);
  }

  @media (max-width: 768px) {
    .main {
      /* Adjusted gradient for mobile */
      background: linear-gradient(117.41deg, #283259 -6.56%, #252e54 5.43%, #1c2241 12.13%, #1C213F 15.54%,#191E3A 30.43%,#252e54 40.43%, #1c2043 60.27%, rgb(25, 29, 60) 70.27%, #202e51 90.56%, #212e54 100.43%);
    }
  }

  h1{
    font-size: 42px;
  }


  button, input[type="submit"], input[type="reset"] {
    //Remove default button style
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }



  //Mobile

  @media (max-width: 700px ) {
    h1{
      font-size: 36px;
    }

  }

  Link, a {
    text-decoration: none;
  }


  /* Custom AOS animation*/

  [data-aos="fade-up-custom"] {
    transform: translateY(50px); // Start slightly up to create the subtle movement
    transition-property: transform; // Only transform property will animate
    transition-timing-function: ease-in-out; // Smooth transition
    transition-duration: 700ms; // Adjust duration as needed
    opacity: 1; // Set to 1 to avoid any opacity change
  }
  
  [data-aos="fade-up-custom"].aos-animate {
    transform: translateY(0); // End at the original position
    transition-property: transform !important;
    // Don't need to set opacity here since it's always 1
  }
  
