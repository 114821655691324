.text-box{
    margin: 1rem;
    padding: 1rem 2rem 1rem 2rem;
    height: fit-content;
    background-color: rgba(255,255,255,0.2);
    border-radius: 2rem;
    top: 200px;
}

.image-container-text-box{
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-box-img{
    width:100%;
    height:100%;
    max-width: 200px;
    max-height: 200px;
    align-self: 'center',
}

.title-box{
    color: white;
}

.text-for-box{
    color: white;
}

@media (max-width: 1000px) {
    .text-box{
        margin: 0.5rem 1rem 0.5rem 1rem;
    }

}
