@import "../variables";
$hero-content-width: 1200px;
$image-width: $hero-content-width/3;
$height: 500px;

.hero {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  .hero-links{
    display: flex;
    height: 100%;
    align-items: center;
    margin-left: 1.5rem;
    a {
      margin: 0 0.5rem 0 0.5rem;
      color: white;
      text-decoration: none; 
      font-size: 1.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    a:hover{
      color: $accent-color;
    }
  }

  .hero-buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem 0 0rem;
  }

   
}

.img-container-skeleton{
  position: relative;
  width: 350px;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;

  .circle-skeleton{
    position: absolute;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    border-radius: 50%;
    animation: skeleton-loading 0.5s linear infinite alternate;
  }

  @keyframes skeleton-loading {
    0% {
      background-color: hsl(53, 100%, 50%);
    }
    100% {
      background-color: hsl(57, 100%, 95%);
    }
}
}

.img-container{
  position: relative;
  width: 350px;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;

img{
  position: absolute;
  width:100%;
  z-index: 2;
}

.parallax-image{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.parallax{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.circle{
  position: absolute;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-color: $accent-color;
  border-radius: 50%;
  
}

}

.interactive-background{
  transition: all 0.5s ease-out;
}


.container {
  position: relative;
  width: $hero-content-width;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  padding: 0rem 6rem 0rem 6rem;
  flex-wrap: wrap;
  flex-flow: row wrap-reverse;
}

.left{
  width: 50%;
  color:white;
}

@media (max-width: 1120px ) {
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sub-title{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .hero-title{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .left{
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

@media (max-width: 700px ) {
  .container {
    width: 100%;
    padding: 0 4rem 0 4rem;
  }

  .left{
    width: 100%;
  }

  .img-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img{
      position: absolute;
      width:100%;
      z-index: 2;
    }
  }

  .right{
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }

  .circle{
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: $accent-color;
    border-radius: 50%;
    
    #skeleton{
      background-color: gray !important;
    }
  }
  
  
}

.right{
  margin: 3rem 1rem 3rem 1rem;
}

.left{

  h1{
    font-weight: 900;
    margin: 0.5rem 0rem 0.25rem 0rem;
  }
  .sub-title{
    font-weight:normal;
    font-size: 1.5rem;
    color:white;
    margin: 0.25rem 0rem 1rem 0rem;
  }
}

.wrapper{
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  perspective: 2px;
}

.container2 img {
  height: 200px;
  width: 200px;
  position: fixed;
  top: 0px;
}

.animation{
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -100;
}

@media (max-width: 500px ) {
  .hero-buttons{
    flex-direction: column;
    padding: 0 0 0 0 !important;

    .hero-links{
      margin-left: 0rem;
      margin-top: 1rem;
    }
  }

  .container {
    padding: 0 1.5rem 0 1.5rem;
  }
}


